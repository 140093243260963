import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Main from '@templates/Main'
import { useInView } from 'react-intersection-observer'

import AsideNav from '@atoms/AsideNav'
import HorWrap from '@atoms/HorWrap'
import PurpleHeader from '@atoms/PurpleHeader'

import HeroSection from '@organisms/HeroSection'
import OnlyText from '@organisms/OnlyText'
import WorkBig from '@organisms/WorkBig'
import PurpleSection from '@organisms/PurpleSection'
import Testimonials from '@organisms/Testimonials'
import Newsletter from '@organisms/Newsletter'

import CreativeVideo from '@images/videos/creative.mp4'
import HeroVideo from '@images/videos/hero-work.mp4'

import ClientLogo1 from '@images/clients/spotify.svg'
import ClientLogo2 from '@images/clients/nike.svg'
import ClientLogo3 from '@images/clients/mirimar.svg'
import ClientLogo4 from '@images/clients/klarna.svg'
import ClientLogo5 from '@images/clients/jayne.svg'
import ClientLogo6 from '@images/clients/froodie.svg'
import ClientLogo7 from '@images/clients/froodie.svg'
import ClientLogo8 from '@images/clients/f.svg'

import AwardLogo1 from '@images/awards/award1.svg'
import AwardLogo2 from '@images/awards/award2.svg'
import AwardLogo3 from '@images/awards/award3.svg'
import AwardLogo4 from '@images/awards/award4.svg'
import AwardLogo5 from '@images/awards/award5.svg'
import AwardLogo6 from '@images/awards/award6.svg'

const WorkPage = ({ data, transitionStatus }) => {
  const pageData = data.datoCmsWorkPage
  const [activeSection, setActiveSection] = useState('#sec-one')
  const [refSec2, Sec2InView] = useInView({
    threshold: 0,
    triggerOnce: true,
  })
  const [refSec3, Sec3InView] = useInView({
    threshold: 0,
    triggerOnce: true,
  })
  const [refSec4, Sec4InView] = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const [startPage, setStartPage] = useState(false)

  useEffect(() => {
    setTimeout(() => setStartPage(true), 100)
  }, [setStartPage])

  const navigation = [
    {
      id: '001',
      name: 'Hello',
      url: '#sec-one',
    },
    {
      id: '002',
      name: 'You. We. Us.',
      url: '#sec-two',
    },
    {
      id: '003',
      name: 'Team',
      url: '#sec-three',
    },
    {
      id: '004',
      name: 'Our services',
      url: '#sec-four',
    },
    {
      id: '005',
      name: 'Clients',
      url: '#sec-five',
    },
    {
      id: '005',
      name: 'Awards',
      url: '#sec-six',
    },
  ]

  const clients = [
    { id: '01', logo: ClientLogo1 },
    { id: '02', logo: ClientLogo2 },
    { id: '03', logo: ClientLogo3 },
    { id: '04', logo: ClientLogo4 },
    { id: '05', logo: ClientLogo5 },
    { id: '06', logo: ClientLogo6 },
    { id: '07', logo: ClientLogo7 },
    { id: '08', logo: ClientLogo8 },
  ]

  const awards = [
    { id: '01', logo: AwardLogo1 },
    { id: '02', logo: AwardLogo2 },
    { id: '03', logo: AwardLogo3 },
    { id: '04', logo: AwardLogo4 },
    { id: '05', logo: AwardLogo5 },
    { id: '06', logo: AwardLogo6 },
  ]

  return (
    <Main
      seo={pageData.seoMetaTags}
      transitionStatus={transitionStatus}
      enterColor="#4100F5"
    >
      <div id="sec-one">
        <HeroSection
          active={startPage}
          video="817495704"
          mobileVideo="817493786"
          data={{
            header: pageData.heroHeader,
            line1: pageData.heroLine1,
            line2: pageData.heroLine2,
            button: pageData.heroButton[0].text,
            buttonLink: `/${pageData.heroButton[0].internalLink.slug}`,
          }}
        />
      </div>
      <div ref={refSec2} id="sec-two">
        <HorWrap>
          <OnlyText
            orangeHeader="02 What we do"
            header={
              <PurpleHeader
                active={Sec2InView}
                delay={2}
                text={pageData.wwdHeader.split(' ')}
              />
            }
            active={Sec2InView}
          >
            <p>{pageData.wwdDescription}</p>
          </OnlyText>
        </HorWrap>
      </div>
      <div ref={refSec3} id="sec-three">
        <WorkBig active={Sec3InView} />
      </div>
      <div ref={refSec4} id="sec-four">
        <PurpleSection active={Sec4InView} />
      </div>
      <Newsletter />
      <Testimonials />
    </Main>
  )
}

export const query = graphql`
  query WorkPageQuery {
    datoCmsWorkPage {
      seoMetaTags {
        tags
      }
      slug
      heroHeader
      heroLine1
      heroLine2
      heroButton {
        text
        textMobile
        internalLink {
          ... on DatoCmsContactPage {
            slug
          }
        }
      }
      wwdHeader
      wwdDescription
    }
  }
`

export default WorkPage
